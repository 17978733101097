import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { CliftonLocalUrl, LocalUrl } from "../../config/env";
import { Gallery, Plus } from "../../config/icon";
import CloseIcon from '@mui/icons-material/Close';

function Product() {
  const [categories, setCategories] = useState();
  const [categoryId, setCategoryId] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [sku, setSku] = useState("");
  const [stock, setStock] = useState(true);
  const [selectedGalleryImages, setSelectedGalleryImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  const priceVariable = Number(price);
  const skuVariable = Number(sku);
  const branch = localStorage.getItem("branchName");

  useEffect(() => {
    const getCategory = () => {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        `${branch === "Bahadurabad" ? LocalUrl : CliftonLocalUrl
        }/category/get-category`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          setCategories(result);
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    getCategory();
  }, []);

  const handleGalleryImageChange = (e) => {
    const files = Array.from(e.target.files);
    const images = files.map((file) => ({
      file,
      url: URL.createObjectURL(file),
    }));
    setSelectedGalleryImages((prevImages) => [...prevImages, ...images]);
    e.target.value = null
  };

  const addProduct = async () => {
    if (!name || !description || !skuVariable || !priceVariable || !selectedGalleryImages.length) {
      toast.error("Please Fill Inputs");
    } else {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", name);
      formData.append("description", description);
      formData.append("sku", sku);
      formData.append("price", price);
      formData.append("instock", stock);

      selectedGalleryImages.forEach((image, index) => {
        formData.append("images", image.file);
      });

      var requestOptions = {
        method: "POST",
        body: formData,
      };

      fetch(
        `${LocalUrl}/SimpleProduct/addProduct/${categoryId}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result, "re");
          setLoading(false);
          if (result.addData) {
            toast.success("Successfully Simple Product Add")
            setCategoryId("");
            setName("");
            setDescription("");
            setSku("");
            setPrice("");
            setStock(true);
            setSelectedGalleryImages([]);
            // resetFileInput(); // Reset the file input
            // formRef.current.reset();
          } else {
            toast.error(result.message);
          }
        })
        .catch((error) => {
          console.log(error, "err");
          toast.error(error);
          setLoading(false);
        });
    }
  };
  const removeImage = (index) => {
    const updatedImages = [...selectedGalleryImages];
    updatedImages.splice(index, 1);
    setSelectedGalleryImages(updatedImages);
  };
  return (
    <Container sx={{ mt: 5 }} maxWidth="lg">
      <Typography variant="h4" sx={{ mb: 2 }}>
        Add Simple Product
      </Typography>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
        component="form"
      >
        <Grid item xs={7.5}>
          <Box
            rowrpacing={1}
            columnspacing={{ xs: 1, sm: 2, md: 3 }}
            className="main-order-table glass-morphism"
          >
            <Grid container>
              <Typography variant="h6" sx={{ marginBottom: "10px" }}>Select Category</Typography>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Category
                </InputLabel>
                <Select
                  required
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={categoryId}
                  label="Category"
                  onChange={(e) => setCategoryId(e.target.value)}
                >
                  {categories &&
                    categories?.map((e, i) => (
                      <MenuItem value={e.uniqueId} key={i}>
                        {e.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid container sx={{ marginTop: 2 }}>
              <Typography variant="h6" sx={{ marginBottom: "10px" }}>Product Name</Typography>
              <TextField
                required
                id="outlined-basic"
                placeholder="Product Name"
                variant="outlined"
                fullWidth
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </Grid>
            <Grid container sx={{ marginTop: 2 }}>
              <Typography variant="h6" sx={{ marginBottom: "10px" }}>
                Product Description
              </Typography>
              <TextField
                required
                id="outlined-basic"
                placeholder="Product Description"
                variant="outlined"
                fullWidth
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />
            </Grid>
            <Grid container sx={{ marginTop: 2 }}>
              <Typography variant="h6" sx={{ marginBottom: "10px" }}>Product Sku</Typography>
              <TextField
                required
                id="outlined-basic"
                placeholder="Product Sku"
                variant="outlined"
                fullWidth
                type="number"
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/[^0-9]/g, "");
                  setSku(numericValue);
                }}
                value={sku}
              />
            </Grid>
            <Grid container sx={{ marginTop: 2 }}>
              <Typography variant="h6" sx={{ marginBottom: "10px" }}>Product Price</Typography>
              <TextField
                required
                id="outlined-basic"
                placeholder="Product Price"
                variant="outlined"
                fullWidth
                type="number"
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/[^0-9]/g, "");
                  setPrice(numericValue);
                }}
                value={price}
              />
            </Grid>
            <Grid
              container
              sx={{
                marginTop: 2,
              }}
            >
              <Box>
                <Typography variant="h6" sx={{ marginBottom: "10px" }}>Product Stock</Typography>
                <FormGroup>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="true"
                    name="radio-buttons-group"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "row",
                    }}
                  >
                    <FormControlLabel
                      value="true"
                      control={
                        <Radio
                          sx={{
                            color: "#FFF",
                            "&.Mui-checked": { color: "#3f51b5" },
                          }}
                        />
                      }
                      label="In Stock"
                      onChange={(e) => setStock(e.target.value)}
                    />
                    <FormControlLabel
                      value="false"
                      control={
                        <Radio
                          sx={{
                            color: "#FFF",
                            "&.Mui-checked": { color: "#3f51b5" },
                          }}
                        />
                      }
                      label="Out Stock"
                      onChange={(e) => setStock(e.target.value)}
                    />
                  </RadioGroup>
                </FormGroup>
              </Box>
            </Grid>
            <Grid container sx={{ marginTop: 2 }}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "end", width: "100%" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={addProduct}
                // fullWidth
                >
                  {loading ? <CircularProgress size={24} /> : "Add Product"}
                </Button>
              </div>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            rowrpacing={1}
            columnspacing={{ xs: 1, sm: 2, md: 3 }}
            className="main-order-table glass-morphism"
          >
            <Typography variant="h6">Product Images</Typography>
            <div
              style={{
                display: "flex",
                // alignItems: "center",
                // justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              {selectedGalleryImages &&
                selectedGalleryImages.map((image, index) => (
                  <div style={{
                    position: "relative",
                    marginRight: "10px"
                  }}>
                    <div style={{
                      position: "absolute", right: -4, zIndex: 9, top: 0, background: "#fff", padding: "3px",
                      display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "50%"
                    }} onClick={() => removeImage(index)}>
                      <CloseIcon fontSize='25' sx={{ cursor: "pointer", }} />
                    </div>
                    <img
                      key={index}
                      src={image.url}
                      alt={`Selected ${index + 1}`}
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "10px",
                        marginTop: 5,
                      }}
                    />
                  </div>
                ))}
              {
                selectedGalleryImages.length >= 1 ? <Grid item sx={{ width: "100px", height: "103px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <label for="upload-photo" style={{
                    border: "2px dashed #fff",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "5px",
                    borderRadius: "10px",
                    cursor: "pointer",
                    height: "100%",
                    justifyContent: "center"
                  }}>
                    <img src={Plus} alt="" width={30} height={30} />
                    <Typography variant="p" sx={{ fontSize: "12px", fontWeight: "500" }}>Upload More</Typography>
                  </label>
                  <input
                    id="upload-photo"
                    style={{ opacity: 0, position: "absolute", zIndex: -1 }}
                    fullWidth
                    type="file"
                    onChange={handleGalleryImageChange}
                    ref={fileInputRef}
                  />
                </Grid> : <></>
              }

            </div>
            {
              selectedGalleryImages.length >= 1 ? null :

                <Grid container sx={{ marginTop: 2 }}>
                  <label for="upload-photo" className="image-upload-customize">
                    <img src={Gallery} alt="" width={80} height={80} />
                    <Typography variant="h6">Upload Product Image</Typography>
                  </label>
                  <input
                    id="upload-photo"
                    style={{ opacity: 0, position: "absolute", zIndex: -1 }}
                    fullWidth
                    type="file"
                    onChange={handleGalleryImageChange}
                    ref={fileInputRef}
                  />
                </Grid>
            }
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
export default Product;
